<template>
  <div class="page page-home primary">
    <v-container>
      <v-row>
        <v-col cols="10" offset-md="1">
          <v-row class="mt-4 justify-center align-center">
            <v-col cols="12">
              <v-row class="justify-space-between">
                <div class="d-flex white--text align-center">
                  <div class="text-h3 font-weight-medium">
                    Hello
                    <span class="text-h3 accent--text font-weight-book">{{
                      fullname.charAt(0).toUpperCase() + fullname.slice(1)
                    }}</span>
                    !
                  </div>
                </div>
                <v-spacer></v-spacer>
                <v-img
                  lazy-src="/img/xtramile-logo-animated.gif"
                  max-width="400"
                  src="/img/xtramile-logo-animated-white.gif"
                ></v-img>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" class="pa-0 ma-0">
              <v-card color="primary" class="height-100">
                <v-card-title class="white--text pl-6">
                  Cout par acquisition d'un candidat (CPA)
                </v-card-title>
                <v-card-text class="pb-0 pt-5 px-0">
                  <chart-component
                    class="height-50"
                    :key="updateKey"
                    :loading="historyLoading"
                    type="line-bar"
                    :values="lineBarChartData"
                    theme="dark"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2" offset="1" class="align-content-space-between pa-0">
              <v-card color="primary">
                <Loading :value="publicationLoading" />
                <v-card-title class="white--text py-7">
                  <h2 class="number-card">{{publicationCount}}</h2>
                </v-card-title>
                <v-card-subtitle class="grey--text">
                  {{ $tc("HOME.PUBLISHED_OFFERS", 2) }}
                </v-card-subtitle>
              </v-card>
              <v-card color="primary" class="mt-2">
                <Loading :value="allPublicationsLoading" />
                <v-card-title class="white--text py-7">
                  <h2 class="number-card">{{allPublicationCount}}</h2>
                </v-card-title>
                <v-card-subtitle class="grey--text">
                  {{ $tc("HOME.PARSED_OFFERS", 2) }}
                </v-card-subtitle>
              </v-card>
              <v-card color="primary" class="mt-2">
                <Loading :value="publicationLoading" />
                <v-card-title class="white--text py-7">
                  <h2 class="number-card">{{applicationCount}}</h2>
                </v-card-title>
                <v-card-subtitle class="grey--text">
                  {{ $tc("HOME.APPLICATIONS", 2) }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
.font-weight-book {
  font-weight: 700;
}

.number-card {
  font-family: sans-serif;
}

.page-home::after {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: inherit;
  background-image: url("/img/bg/people-working-smile.jpg");
  background-position: center center;
  background-size: cover;
  background-blend-mode: overlay;
  opacity: 0.3;
}

.page-home > * {
  z-index: 1;
}

.first-loading {
  animation: first-loading 1.5s;
  animation-delay: 4.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes first-loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    height: 500px;
  }
  100% {
    opacity: 0;
    height: 0px;
  }
}
</style>

<script>
import { mapState } from "vuex";
import ChartComponent from "@/components/charts/Chart.vue";
import Loading from '@/components/Loading'

export default {
  name: "PageHomeAll",
  components: {
    ChartComponent,
    Loading,
  },
  data: (_this) => ({
    campaignItems: [],
    updateKey: 0,
    historyLoading: false,
    publicationCount: 0,
    allPublicationCount: 0,
    applicationCount: '-',
    lineBarChartData: [
      {
        color: "#423d80",
        // label: _this.$i18n.t("RATIO.CPA"),
        label: "Evolution du cout par acquisition d'un candidat (CPA)",
        unit: "€",
        data: [
          {
            date: "Apr 2020",
            name: "CPA",
            value: 42,
          },
          {
            date: "May 2020",
            name: "CPA",
            value: 36,
          },
          {
            date: "Jun 2020",
            name: "CPA",
            value: 49,
          },
          {
            date: "Jul 2021",
            name: "CPA",
            value: 28,
          },
          {
            date: "Aug 2021",
            name: "CPA",
            value: 21,
          },
          {
            date: "Sep 2021",
            name: "CPA",
            value: 32,
          },
          {
            date: "Oct 2021",
            name: "CPA",
            value: 34,
          },
          {
            date: "Nov 2021",
            name: "CPA",
            value: 25,
          },
        ],
      },
    ],
    publicationLoading: false,
    allPublicationsLoading: false,
  }),

  computed: {
    ...mapState("user", {
      fullname: (state) =>
        state.user ? `${state.user.firstname} ${state.user.lastname}` : "-",
        user: (state) => state.user ? state.user : {}
    }),
  },

  mounted() {
    this.identifyUserInStonly();
    this.loadCampaignItems();
    this.loadAllPublication();
    this.loadPublicationActive();
    this.formatData();
  },


  beforeMount() {},

  methods: {

    identifyUserInStonly () {
      const productsPermissions = this.user.permissions.filter((permission) => permission.name.includes('product.')).map((product) => product.name);
      const informations = {
        email : this.user.email,
        firstName: this.user.firstname,
        name: this.user.lastname,
        product: productsPermissions,
      }

      window.stonlyTrack("identify", `${this.user.firstname}-${this.user.lastname}`, informations)
    },
    
    formatData () {
      let newData= [];

      if (this.lineBarChartData?.data) {
        newData = [...this.lineBarChartData.data];
      }

      if (newData) {
          newData.map(object => {
            if (object.date) {
              const date = new Date(object.date).toLocaleDateString("fr-FR", {
                year: "numeric",
                month: "short",
              });

              
              return {...object, date: date.charAt(0).toUpperCase() + date.slice(1)};
            } 
            else return object;
          })
        }

        this.lineBarChartData.data = newData;
    },

    loadCampaignItems() {
      this.$services.api_programmatic.campaign
        .search({ where: { status: [1] }, limit: 3 })
        .then((response) => {
          this.campaignItems = response.data.slice(0, 6);
        });
    },

    loadAllPublication(options = {}) {
      this.allPublicationsLoading = true;
      this.$queryFilters().save();
      const defaultOptions = {
        order: [["updated_at", "DESC"]],
        mode: "list",
        page: this.page || 1,
        limit: this.limit,
      };

      const query = this.$queryFormat({
        where: {},
        options: Object.assign({}, defaultOptions, { options }),
      });

      this.$emit("childData", { queryPublicationList: query });
      this.$services.api_programmatic.publication
        .search(query)
        .then((response) => {
          if (response.data) {
            this.allPublicationCount = response.data.count.toLocaleString(this.$i18n.locale);
          } else {
            this.allPublicationCount = 0;
          }
        })
        .finally(() => {
          this.allPublicationsLoading = false;
        });
    },

    loadPublicationActive(options = {}) {
      this.publicationLoading = true;
      this.$queryFilters().save();
      const defaultOptions = {
        order: [["updated_at", "DESC"]],
        mode: "list",
        page: this.page || 1,
        limit: this.limit,
      };

      const query = this.$queryFormat({
        where: {status: 1},
        options: Object.assign({}, defaultOptions, { options }),
      });

      this.$emit("childData", { queryPublicationList: query });
      this.$services.api_programmatic.publication
        .search(query)
        .then((response) => {
          if (response.data) {
            this.publicationCount = response.data.count.toLocaleString(this.$i18n.locale) || response.data.length;
          } else {
            this.publicationCount = 0;
          }
        })
        .finally(() => {
          this.publicationLoading = false;
        });
    },

    campaignTimeRemaining(item) {
      return new Date(item.start_date) < new Date()
        ? item.start_date
        : item.end_date;
    },
  },
};
</script>
