<template>
  <div class="page page-notifications primary">
    <PageHeader
      :icon="$t('NOTIFICATION.ICON')"
      :title="$t('NOTIFICATION.TITLES')"
      :dark="true"
    >



    </PageHeader>

    <Loading :value="loading" color="primary" />
    <div class="flex-1 min-height-100 scroll">
      <v-col cols="10" offset-md="1" class="min-height-100 mt-10">
        <v-row class="min-height-90">
          <v-col cols="12" class="min-height-100 ma-0">

            <v-card dark outlined color="primary" class="height-100 border-purple px-10">
              <v-card-title class="pt-5 pl-0">
                <v-tabs background-color="rgba(0,0,0,0)" color="accent" center-active dark>
                  <v-tab @click="scope = null">{{$t('COMMON.ALL')}}</v-tab>
                  <v-tab @click="scope = 'channel_subscription'">{{$t('CHANNEL_SUBSCRIPTION.TITLES')}}</v-tab>
                  <v-tab @click="scope = 'feed'">{{$t('FEED.TITLES')}}</v-tab>
                  <v-tab @click="scope = 'publication'">{{$t('PUBLICATION.TITLES')}}</v-tab>
                  <v-tab @click="scope = 'campaign'">{{$t('CAMPAIGN.TITLES')}}</v-tab>
                  <v-tab @click="scope = 'job'">{{$t('JOB.TITLES')}}</v-tab>
                  <v-spacer />
                  <v-btn depressed outlined rounded class="mt-2" v-if="itemList.length > 0" @click="viewAll"><v-icon class="mr-2">mdi-check</v-icon>{{$t('NOTIFICATION.VIEW_ALL')}}</v-btn>
                </v-tabs>
              </v-card-title>
              <v-card-text class="pb-10 pt-5">

                <div v-if="itemList.length > 0" class="mt-10">
                <template v-for="(item, i) in itemList">
                  <div :class="['notification', 'notification-' + item.status_type, 'mb-4', item.view_at ? 'muted' : '']" :key="'not'+i">
                    <div class="notification-icon">
                      <v-icon v-if="item.status_type === 'error'" :color="item.status_type">mdi-alert-decagram</v-icon>
                      <v-icon v-else-if="item.status_type === 'warning'" :color="item.status_type">mdi-alert-circle</v-icon>
                      <v-icon v-else-if="item.status_type === 'success'" :color="item.status_type">mdi-check-circle</v-icon>
                    </div>
                    <a class="notification-content" :href="redirectUrl(item)" target="_blank">
                      <div >
                        <div :class="['notification-title',item.status_type + '--text', 'text-h6']"><strong>{{$t('NOTIFICATION.SCOPE_' + item.scope.toUpperCase())}}</strong></div>
                        <div class="notification-description white--text">
                          <span>{{handleMessage(item)}}</span>
                          <br /><small class="muted">{{$moment(item.created_at).fromNow()}}</small>
                        </div>
                      </div>
                    </a>
                    <div class="notification-action">
                      <v-btn icon depressed class="mr-3" @click.stop="updateView(item)" style="z-index:1;"><v-icon>mdi-check</v-icon></v-btn>
                      <v-btn icon depressed><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </div>
                  </div>
                </template>
              </div>
              <p class="text-h4" v-else-if="!scope" >{{$t('NOTIFICATION.NO_NEW_NOTIFICATION')}}</p>



              <div class="mt-10" v-if="itemListToday.length > 0">
                <p>{{$t('NOTIFICATION.RECENTLY_VIEW')}}</p>
                <template v-for="(it, i) in itemListToday">
                  <v-divider :key="'dv'+i" />
                  <div class="ma-2" :key="'st'+i">
                    <a :href="redirectUrl(it)" target="_blank">
                      <v-icon size="14" v-if="it.status_type === 'error'" :color="it.status_type">mdi-alert-decagram</v-icon>
                      <v-icon size="14" v-else-if="it.status_type === 'warning'" :color="it.status_type">mdi-alert-circle</v-icon>
                      <v-icon size="14" v-else-if="it.status_type === 'success'" :color="it.status_type">mdi-check-circle</v-icon>
                      <strong :class="[it.status_type + '--text', 'ml-2']">{{$t('NOTIFICATION.SCOPE_' + it.scope.toUpperCase())}}</strong>
                      <span class="ml-4 white--text">{{handleMessage(it)}}</span>
                      <small class="muted ml-4 white--text">{{$moment(it.created_at).fromNow()}}</small>
                    </a>
                  </div>
                </template>
              </div>

              <div class="mt-10" v-if="items.length > 0">
                <p>{{$t('NOTIFICATION.ALREADY_VIEW')}}</p>
                <template v-for="(it, i) in items">
                  <v-divider :key="'dv'+i" />
                  <div class="ma-2" :key="'st'+i">
                    <a :href="redirectUrl(it)" target="_blank">
                      <v-icon size="14" v-if="it.status_type === 'error'" :color="it.status_type">mdi-alert-decagram</v-icon>
                      <v-icon size="14" v-else-if="it.status_type === 'warning'" :color="it.status_type">mdi-alert-circle</v-icon>
                      <v-icon size="14" v-else-if="it.status_type === 'success'" :color="it.status_type">mdi-check-circle</v-icon>
                      <strong :class="[it.status_type + '--text', 'ml-2']">{{$t('NOTIFICATION.SCOPE_' + it.scope.toUpperCase())}}</strong>
                      <span class="ml-4 white--text">{{handleMessage(it)}}</span>
                      <small class="muted ml-4 white--text">{{$moment(it.created_at).fromNow()}}</small>
                    </a>
                  </div>
                </template>
              </div>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<style lang="scss">


.number-card {
  font-family: sans-serif;
}


.border-purple.v-card.v-sheet--outlined {
  border: 1px solid rgba(255,255,255,.1) !important;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.22);
}


.notification {
  display: flex;
  align-items: center;
  padding: 18px;
  cursor: pointer;
  border-radius: 10px !important;
  box-shadow: 0 4px 10px rgba(0,0,0,0.22);
}

.notification-content { flex-grow: 1; display: block;}
.notification-icon { padding: 0 24px 0 12px; }
.notification-title {}
.notification-description {}

.notification-error {
  background: linear-gradient(90deg, rgba(244,67,54,.2) 0%, rgba(0,0,0,.1) 50%);
}

.notification-success {
  background: linear-gradient(90deg, rgba(76,175,80,.2) 0%, rgba(0,0,0,.1) 50%);
}

.notification-info {
  background: linear-gradient(90deg, rgba(33,150,243,.2) 0%, rgba(0,0,0,.1) 50%);
}

.notification-warning {
  background: linear-gradient(90deg, rgba(255,152,0,.2) 0%, rgba(0,0,0,.1) 50%);
}

.notification-finished {
  background: linear-gradient(90deg, rgba(233,30,99,.2) 0%, rgba(0,0,0,.1) 50%);
}


</style>

<script>
import { mapState } from "vuex";
import Loading from '@/components/Loading'
import PageHeader from "@/components/PageHeader"

export default {
  name: "PageNotificationAll",
  components: {
    Loading,
    PageHeader,

  },
  data: (_this) => ({
    loading: false,
    limit: 100,
    items: [],
    count: 0,
    scope: null,
    itemList: [],
    itemListToday: [],
  }),

  watch: {
    scope () {
      this.loadNotificationItems()
    }
  },

  computed: {
    ...mapState("user", {
      fullname: (state) =>
        state.user ? `${state.user.firstname} ${state.user.lastname}` : "-",
        user: (state) => state.user ? state.user : {}
    }),
  },

  mounted() {
    this.loadNotificationItems()
  },


  beforeMount() {},

  methods: {

    handleMessage (item) {
      let message = {}
      if (item.message) {
        item.message.split(' | ').map((v,i) => {
          message['_'+(i+1)] = v
        })
      }
      // console.log(item.status_code, message)
      return this.$i18n.t(item.status_code, message)
    },

    updateView (item) {
      if (!item.view_at) {
        const query = this.$queryFormat({
          where: {id: item.id}
        })
        this.$services.api_notification.notification
        .view(query)
        .catch(e => console.error(e))
        .finally(() => this.loadNotificationItems())
      }
    },

    redirectUrl (item) {
      let product = ''
      const permissions = this.user.permissions
      for (const permission of permissions) {
        if (permission.name === 'role.xadmin') {
          product = 'xprogrammatic'
          break
        }
        if (permission.name === 'product.xtrading') product = 'xtrading'
        if (permission.name === 'product.xdiffusion' && !product) product = 'xdiffusion'
      }
      return document.location.origin + '/' + product + item.redirect_url
    },

    viewAll () {
      // fill view_at
      const ids = this.items.filter(v => !v.view_at).map(v => v.id)
      if (ids.length > 0) {
        this.$services.api_notification.notification
          .view({where:{id:ids}})
          .catch(e => console.error(e))
          .finally(() => this.loadNotificationItems())
      }
    },

    loadNotificationItems(options = {}) {

      this.loading = true

      this.$queryFilters().save();
      const defaultOptions = {
        order: [["updated_at", "DESC"]],
        mode: "list",
        page: this.page || 1,
        limit: this.limit,
      }

      const query = this.$queryFormat({
        where: {status: 1},
        options: Object.assign({}, defaultOptions, { options }),
      })

      if (this.scope) {
        query.where.scope = this.scope
      }

      this.$services.api_notification.notification
        .search(query)
        .then((response) => {
          if (response.data) {
            const items = response.data.items
            if (items.length) {

              const sortByScope = (items) => {
                const scopes = {}
                items.forEach((item, i) => {
                  if (!scopes[item.scope]) scopes[item.scope] = []
                  scopes[item.scope].push(item)
                })
                this.itemsByScope = scopes
              }
              sortByScope(items)


              this.itemList = items.filter( item => !item.view_at)
              this.itemListToday = items.filter( item => item.view_at && new Date(item.created_at)>new Date(Date.now()-60*60*1000*48))
              this.items = items.filter( item => item.view_at && new Date(item.created_at)<new Date(Date.now()-60*60*1000*48))
              this.count = response.data.count
              return 1
            }
          }
          this.items = []
          this.itemList = []
          this.itemListToday = []
          this.count = 0
        })
        .finally(() => {
          this.loading = false;
        })

    },



  },
}
</script>
